<template>
  <div class="loginForm">
    <base-alerts></base-alerts>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(userLogin)">
        <base-input
          className="userName"
          placeholder="Email"
          type="email"
          rules="required|email"
          v-model="loginDetails.email"
          @input="userInput"
          name="Email"
        />
        <base-input
          className="password"
          placeholder="Password"
          type="password"
          rules="required"
          v-model="loginDetails.password"
          @input="passwordInput"
          name="Password"
        />
        <div class="button-row">
          <button
            type="submit"
            to="/user"
            :class="processing ? 'processing' : ''"
            class="btn btn-primary large"
            :style="{width: '47%', marginRight: '12px'}"
          >
            Login to Continue
          </button>
          <!-- [29 Jun 2022] HR - Added to display buttons in same area -->
          <router-link
            to="register"
            class="btn btn-primary large"
            :style="{width: '47%'}"
          >
            Register
          </router-link>
        </div>
        <!-- <div class="or"><span>or</span></div> -->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {  
  data() {
    return {
      loginDetails: {
        email: "",
        password: "",
      },
      processing: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(["login", "logOut"]),
    async userLogin() {
      this.processing = true;
      this.logOut();
      await this.login(this.loginDetails).catch((e) => {
        this.processing = false;
      });
    },
    userInput(data) {
      this.loginDetails.email = data;
    },
    passwordInput(data) {
      this.loginDetails.password = data;
    },
  },
  watch: {
    user() {
      const size = Object.keys(this.user).length;
      if (size != 0) {
        if (this.user.type == 0) {
          this.$router.push("/user");
        } else {
          this.$router.push("/brand");
        }
      }
    },
    // "$store.state.alert.message"() {
    //   this.alert.message == null
    //     ? (this.processing = true)
    //     : (this.processing = false);
    // },
  },
};
</script>

<style lang="scss" scoped>
.button-row {
  @include flex(center, center);
}
</style>

<style lang="scss">
form {
  max-width: 440px;
  margin: 0;
}
.button-row {
  margin-top: rem(40px);
  // @include flex(center, center);
  @media screen and (max-width: 767px) {
    margin-top: rem(25px);
  }
  .btn{
    margin:10px 0px;
  }
}
.form-group {
  margin-bottom: rem(25px);
  @media screen and (max-width: 767px) {
    margin-bottom: rem(16px);
  }
}
</style>
